import { ActionContext } from 'vuex'
import constants from './-constants'
import { WebRequest } from '~/helpers/api'
import {
  BuildingAvailabilityQuery,
  FloorAvailabilityQuery, MeasurementRangeQuery,
  ProjectAvailabilityQuery,
  SpaceAvailabilityQuery
} from '~/helpers/api/modules/AvailabilityApiModule'
import { AvailabilityFilters } from '~/store/availability/-types'
import {ProjectFocusMode} from "~/store/building/-constants";

const {
  LOAD_PROJECT_AVAILABILITY_DATA,
  LOAD_BUILDING_AVAILABILITY_DATA,
  LOAD_FLOOR_AVAILABILITY_DATA,
  LOAD_SPACE_AVAILABILITY_DATA,
  LOAD_SPACE_STATISTICS_DATA,
  UPDATE_FILTERS,
  CLEAR_FILTERS,
  UPDATE_LEASED_SPACES_VISIBILITY,
} = constants.action
const {
  SET_PROJECT_AVAILABILITY_DATA,
  SET_BUILDING_AVAILABILITY_DATA,
  SET_FLOOR_AVAILABILITY_DATA,
  SET_SPACE_AVAILABILITY_DATA,
  SET_SPACE_STATISTICS_DATA,
  SET_FILTERS,
  RESET_FILTERS,
  SET_IS_LOADING_DATA,
  SET_LEASED_SPACES_VISIBILITY,
  SET_IS_LOADING_STATISTICS_DATA,
} = constants.mutation

export default {
  [LOAD_PROJECT_AVAILABILITY_DATA](
    { commit, dispatch, state, rootGetters }: ActionContext<any, any>,
    payload: ProjectAvailabilityQuery
  ) {
    commit(SET_IS_LOADING_DATA, true)
    // commit(SET_PROJECT_AVAILABILITY_DATA, [])
    return new Promise((resolve, reject) => {
      const query = {...payload}
      // @ts-ignore
      WebRequest.GET(this.$webApi.availability.projectAvailability(query))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY_DATA, res.data.result)
          commit(SET_IS_LOADING_DATA, false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_PROJECT_AVAILABILITY_DATA, [])
          commit(SET_IS_LOADING_DATA, false)
          reject(new Error(e.message))
        })
    })
  },
  [LOAD_BUILDING_AVAILABILITY_DATA](
    { commit, dispatch, rootGetters }: ActionContext<any, any>,
    payload: BuildingAvailabilityQuery
  ) {
    commit(SET_IS_LOADING_DATA, true)
    // commit(SET_BUILDING_AVAILABILITY_DATA, [])
    return new Promise((resolve, reject) => {
      const query = {...payload}
      // @ts-ignore
      WebRequest.GET(this.$webApi.availability.buildingAvailability(query))
        .then((res: any) => {
          commit(SET_BUILDING_AVAILABILITY_DATA, res.data.result)
          commit(SET_IS_LOADING_DATA, false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_BUILDING_AVAILABILITY_DATA, [])
          commit(SET_IS_LOADING_DATA, false)
          reject(new Error(e.message))
        })
    })
  },
  [LOAD_FLOOR_AVAILABILITY_DATA](
    { commit, dispatch, rootState }: ActionContext<any, any>,
    payload: FloorAvailabilityQuery
  ) {
    if (![ProjectFocusMode.FLOOR, ProjectFocusMode.SPACE].includes(rootState.building.projectFocusMode)) {
      commit(SET_IS_LOADING_DATA, true)
    }
    return new Promise((resolve, reject) => {
      // @ts-ignore
      WebRequest.GET(this.$webApi.availability.floorAvailability(payload))
        .then((res: any) => {
          commit(SET_FLOOR_AVAILABILITY_DATA, res.data.result)
          commit(SET_IS_LOADING_DATA, false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_FLOOR_AVAILABILITY_DATA, [])
          commit(SET_IS_LOADING_DATA, false)
          reject(new Error(e.message))
        })
    })
  },
  [LOAD_SPACE_AVAILABILITY_DATA](
    { commit, dispatch, rootState }: ActionContext<any, any>,
    payload: SpaceAvailabilityQuery
  ) {
    if (![ProjectFocusMode.FLOOR, ProjectFocusMode.SPACE].includes(rootState.building.projectFocusMode)) {
      commit(SET_IS_LOADING_DATA, true)
    }
    dispatch(LOAD_SPACE_STATISTICS_DATA);
    commit(SET_SPACE_AVAILABILITY_DATA, []);
    return new Promise((resolve, reject) => {
      // @ts-ignore
      WebRequest.GET(this.$webApi.availability.spaceAvailability(payload))
        .then((res: any) => {
          commit(SET_SPACE_AVAILABILITY_DATA, res.data.result)
          commit(SET_IS_LOADING_DATA, false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_SPACE_AVAILABILITY_DATA, [])
          commit(SET_IS_LOADING_DATA, false)
          reject(new Error(e.message))
        })
    })
  },
  [LOAD_SPACE_STATISTICS_DATA](
    { commit, state }: ActionContext<any, any>,
  ) {
    commit(SET_IS_LOADING_STATISTICS_DATA, true)
    return new Promise((resolve, reject) => {
      const {clearedFilters, surfaceRange, ...restStoreFilters} = state.filters;
      // @ts-ignore
      WebRequest.GET(this.$webApi.availability.spaceStatistics({
        ...restStoreFilters,
        min_surface: surfaceRange[state.filters.surface_field].min,
        max_surface: surfaceRange[state.filters.surface_field].max,
      }))
        .then((res: any) => {
          commit(SET_SPACE_STATISTICS_DATA, res.data.result)
          commit(SET_IS_LOADING_STATISTICS_DATA, false)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_IS_LOADING_STATISTICS_DATA, {
            totalSpaces: 0,
            availableSpaces: 0,
            unavailableSpaces: 0
          })
          commit(SET_IS_LOADING_DATA, false)
          reject(new Error(e.message))
        })
    })
  },
  [UPDATE_FILTERS]({ commit, rootState }: ActionContext<any, any>, payload: AvailabilityFilters) {
    if (![ProjectFocusMode.FLOOR, ProjectFocusMode.SPACE].includes(rootState.building.projectFocusMode)) {
      commit(SET_IS_LOADING_DATA, true)
    }
    commit(SET_FILTERS, payload)
  },
  [CLEAR_FILTERS]({ commit, rootState }: ActionContext<any, any>) {
    const project = rootState.project.projects.find((p: any) => p.slug === rootState.project.activeProject);
    commit(RESET_FILTERS, project.surfaceRange)
  },
  [UPDATE_LEASED_SPACES_VISIBILITY](
    { commit, rootGetters }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_LEASED_SPACES_VISIBILITY, payload)
  }
}
