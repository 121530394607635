import baseConstants from './base/-constants'
import requestOfferConstants from './requestOffer/-constants'

export default {
  getActiveProjectSettings(state: any) {
    const allSettings = state.base.meta.generalConfig.modelDisplaySettings
    return allSettings[state.project.activeProject]
  },
  cdnBase(state: any) {
    return state.base.meta.generalConfig.cdnBase
  },
  logoImage(state: any) {
    return state.base.meta.generalConfig.logo
  },
  logoImageWhite(state: any) {
    return state.base.meta.generalConfig.logoWhite
  },
  getActiveProjectData(state: any) {
    return state.project.projects.find((item: any) => item.slug === state.project.activeProject)
  },
  constants(library: String) {
    switch (library) {
      case 'base':
        return baseConstants
      case 'requestOffer':
        return requestOfferConstants
    }
  },
  commonFeatures(state: any) {
    return state.base.meta.generalConfig.features.common
  },
  featuresForProject: (state: any) => (projectSlug: string) => {
    return {
      ...state.base.meta.generalConfig.features.common,
      ...state.base.meta.generalConfig.features[projectSlug]
    }
  }
}
